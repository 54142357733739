import React from 'react'
import { withNullErrorBoundary } from '../../../../utils'
import Link from '../../../link'
import ShippingMethods from './shipping-methods'

const AddressOptions = () => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <label className="label">Shipping options:</label>
        <div className="readmore-wrp">
          <Link href="/terms" className="read-more">learn more</Link>
        </div>
      </div>

      <div className="col-12 in-line shipping-option">
        <ShippingMethods />
      </div>
    </>
  )
}

export default withNullErrorBoundary(AddressOptions)
