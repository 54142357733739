import { motion } from '../../../../../motion'
import React, { useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeMode, selectors } from '../../../../../../store/reducers/slices/addresses'
import { createOrUpdateAddress } from '../../../../../../store/thunks/account/create-or-update-address'
import { AddressForm } from '../../../../../address-form/address-form'
import { addressSchema } from '../../../../checkout-step-2'

export const Form = ({ uuid, type, children }) => {
  const dispatch = useDispatch()
  const isLogged = useSelector(state => state.user.isLogged)
  const newUser = useSelector(state => state.addresses.newUser)
  const isNew = useSelector(state => !selectors.selectById(state, uuid).id)
  const close = () => dispatch(changeMode({ uuid, mode: isNew ? 'button' : 'card' }))
  const shippingAndBilling = useSelector(state => state.checkout.form.shippingSameAsBilling)
  const address = useSelector( // Only re-render when the mode is different
    state => selectors.selectById(state, uuid),
    (a, b) => a.mode === b.mode
  )

  const buttonRef = useRef()
  const onClick = () => buttonRef.current.click()

  const saveAddress = useMemo(() => isLogged ? (values) => {
    if (!addressSchema.isValidSync(values[type])) {
      return
    }

    const isBillingAddress = type === 'billing'
    let isShippingAddress = type === 'shipping'
    let isBillingAndShippingAddress = false

    if (isShippingAddress && shippingAndBilling) {
      isShippingAddress = false
      isBillingAndShippingAddress = true
    }

    const data = type ? values[type] : values
    dispatch(createOrUpdateAddress({
      uuid,
      ...data,
      isShippingAddress,
      isBillingAddress,
      isBillingAndShippingAddress,
    }))
  } : () => {}, [isLogged])

  return (
    <>
      <motion.div
        layout
        className="address-form__wrapper"
        variants={{
          show: { opacity: 1, transition: { delay: 0.2 } },
          hide: { opacity: 0 },
        }}
        initial="hide"
        animate="show"
        exit="hide"
      >
        <AddressForm uuid={uuid} initialValues={address} exclude={isLogged ? [] : ['label', 'saveAddress']} onSubmit={saveAddress} prefix={type}>
          <button type="submit" ref={buttonRef} hidden />
        </AddressForm>
      </motion.div>
      {!newUser && (
        <motion.footer className="address-form__footer">
          <SubmitButton component={children} uuid={uuid} onClick={onClick} type="button">
            Save
          </SubmitButton>
          <button type="button" className="cancel-button" onClick={close}>
            Cancel
          </button>
        </motion.footer>
      )}
    </>
  )
}

const SubmitButton = ({ uuid, component: ChildComponent, ...props }) => {
  const isValid = useSelector(state => selectors.selectById(state, uuid).isValid)

  return (
    <ChildComponent disabled={!isValid} { ...props } />
  )
}
