import { gql } from 'apollo-boost'

const AddressFragment = gql`
  fragment address on AddressInterface {
    id
    attention
    title
    fullName
    firstName
    lastName
    address1
    address2
    address3
    city
    zipCode
    phone
    alternativePhone
    label
    businessName
    city
    country
    countryId
    state
    stateId
  }
`

export default AddressFragment
