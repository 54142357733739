import { AnimatePresence } from 'framer-motion'
import { motion } from '../../../../../motion'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeMode, selectors } from '../../../../../../store/reducers/slices/addresses'
import { setBillingAddress, setShippingAddress } from '../../../../../../store/thunks/checkout/shipping-addresses'
import { withNullErrorBoundary } from '../../../../../../utils'
import FormCheckoutRadioOption from '../../../../../form/form-checkout-radio-option'

export const Card = ({ uuid, type, children }) => {
  const address = useSelector(state => selectors.selectById(state, uuid))
  const dispatch = useDispatch()
  const edit = () => dispatch(changeMode({ uuid, mode: 'form' }))
  const { title, body, phone } = useAddress(address)

  const { shippingAddressId, billingAddressId } = useSelector(state => state.checkout.form)
  const setSelectedMethod = () => dispatch(type === 'shipping' ? setShippingAddress(uuid) : setBillingAddress(uuid))
  const isSelected = useSelector(state => Number(state.checkout.form[`${type}AddressId`]) === Number(address.id))
  const shippingSameAsBilling = shippingAddressId === billingAddressId
  const showFooter = isSelected && (!shippingSameAsBilling || type !== 'billing')

  return (
    <>
      <motion.div
        layout
        style={{ display: 'flex', alignItems: 'stretch' }}
        transition={{ delay: 0.2 }}
        variants={{
          show: { opacity: 1 },
          hide: { opacity: 0 },
        }}
        initial="hide"
        animate="show"
        exit="hide"
      >
        <FormCheckoutRadioOption
          style={{ width: '100%' }}
          onChange={setSelectedMethod}
          checked={isSelected}
          className={'form-check'}
          uniqueName={`checkout-${type}-address-${uuid}`}
          groupName={`${type}-address`}
        >
          <motion.div layout>
            <AnimatePresence>
              <motion.div
                key={'body'}
                className="label-data"
              >
                {title}
                <span className="address">{body}</span>
              </motion.div>
              {phone && (
                <motion.div
                  key={'phone'}
                  transition={{ delay: 0.2 }}
                  variants={{
                    show: { opacity: 1 },
                    hide: { opacity: 0 },
                  }}
                  initial="hide"
                  animate="show"
                  exit="hide"
                  className="contact"
                >
                  <span><i className="far fa-phone"/>{phone}</span>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </FormCheckoutRadioOption>
      </motion.div>
      <AnimatePresence initial={false}>
        {showFooter && (
          <motion.footer
            key={'edit'}
            variants={{
              hide: { opacity: 0, height: 0 },
              show: { opacity: 1, height: 'auto' },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            transition={{
              type: 'spring',
              stiffness: 200,
              damping: 25,
            }}
          >
            <div className="address-card__footer">
              {children({
                type: 'button',
                onClick: edit,
                children: 'Edit',
              })}
            </div>
          </motion.footer>
        )}
      </AnimatePresence>
    </>
  )
}

export default withNullErrorBoundary(Card)

const useAddress = (address) => {
  const { label, fullName, firstName, lastName, phone } = address
  const name = fullName ? fullName : `${firstName} ${lastName}`
  const title = label ? `${label} - ${name}` : name
  const newAddress = { ...address }
  const country = useSelector(state => state.checkout.data.countries.find(c => Number(c.id) === Number(address.countryId)))

  if (!address.id) {
    if (country) {
      newAddress.country = country.name
    }

    if (address.stateId && country) {
      newAddress.state = country.states.find(s => Number(s.id) === Number(address.stateId)).name
    }
  }

  return { title, phone, body: buildText(newAddress) }
}

const buildText = (address) => [
  address.address1,
  address.address2,
  address.address3,
  address.city,
  address.zipCode,
  address.state,
  address.country,
].filter(s => Boolean(s)).join(', ')
