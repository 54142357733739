import { createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from 'apollo-boost'
import { client } from '../../../apollo/client'
import { selectors } from '../../reducers/slices/addresses'

const updateAddress = async (mutation, idOrUuid, api) => {
  const number = api.getState().cart.number

  if (number === undefined) {
    throw new Error('Cart is empty')
  }

  let addressId = idOrUuid
  if (isNaN(addressId)) { // This is a UUID
    addressId = selectors.selectById(api.getState(), addressId)?.id
  }

  if (!addressId) {
    throw new Error('Failed to find address')
  }

  const { data } = await client.mutate({
    mutation,
    variables: { number, addressId },
  })

  return data.updateCart
}

export const setShippingAddress = createAsyncThunk('checkout/setShippingAddress', async (addressId, api) => {
  const mutation = api.getState().checkout.form.shippingSameAsBilling ? UPDATE_BILLING_AND_SHIPPING_ADDRESS : UPDATE_SHIPPING_ADDRESS

  return updateAddress(mutation, addressId, api)
})

export const setBillingAddress = createAsyncThunk('checkout/setBillingAddress', async (addressId, api) => {
  return updateAddress(UPDATE_BILLING_ADDRESS, addressId, api)
})

export const setBillingAndShippingAddress = createAsyncThunk('checkout/setBillingAndShippingAddress', async (addressId, api) => {
  return updateAddress(UPDATE_BILLING_AND_SHIPPING_ADDRESS, addressId, api)
})

const AddressFragment = gql`
  fragment AddressFragment on OrderInterface {
    shippingAddressId
    billingAddressId
    shippingSameAsBilling
  }
`

const UPDATE_BILLING_ADDRESS = gql`
  ${AddressFragment}

  mutation UpdateBillingAddress($number: ID!, $addressId: ID!) {
    updateCart(
      number: $number,
      billingAddressId: $addressId,
    ) {
      ...AddressFragment
    }
  }
`

const UPDATE_SHIPPING_ADDRESS = gql`
  ${AddressFragment}

  mutation UpdateShippingAddress($number: ID!, $addressId: ID!) {
    updateCart(
      number: $number,
      shippingAddressId: $addressId,
    ) {
      ...AddressFragment
    }
  }
`

const UPDATE_BILLING_AND_SHIPPING_ADDRESS = gql`
  ${AddressFragment}

  mutation UpdateBillingAndShippingAddress($number: ID!, $addressId: ID!) {
    updateCart(
      number: $number,
      shippingAddressId: $addressId,
      billingAddressId: $addressId,
    ) {
      ...AddressFragment
    }
  }
`
