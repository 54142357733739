import {
  EVENT_COMMERCE_CART_ADD, EVENT_COMMERCE_CART_REMOVE, EVENT_COMMERCE_CHECKOUT_PROGRESS,
  EVENT_COMMERCE_PRODUCT_CLICK,
  EVENT_COMMERCE_PRODUCT_IMPRESSION,
  EVENT_COMMERCE_PRODUCT_VIEW, EVENT_COMMERCE_PURCHASE,
} from '../events'

export function getDataLayer() {
  if (typeof window?.dataLayer === 'undefined') {
    window.dataLayer = []
  }

  return window.dataLayer
}

export const advancedAnalyticsHandler = {
  handle: (event, data) => {
    const dataLayer = getDataLayer()
    const ga4Data = event === EVENT_COMMERCE_PURCHASE ? mapGa4Data(data.products) : mapGa4Data(data)

    switch (event) {
    case EVENT_COMMERCE_PRODUCT_IMPRESSION:
      dataLayer.push({ // UA event
        ecommerce: { currencyCode: 'AUD', impressions: data },
      })
      break
    case EVENT_COMMERCE_PRODUCT_CLICK:
      dataLayer.push({
        event: 'productClick', // UA event
        ecommerce: { products: data },
      }, {
        event: 'select_item', // GA4 event
        ecommerce: {
          item_list_name: ga4Data[0].item_list_name,
          items: ga4Data,
        },
      })
      break
    case EVENT_COMMERCE_PRODUCT_VIEW:
      dataLayer.push({ 
        ecommerce: { // UA event
          detail: { products: data },
        },
      }, {
        event: 'view_item', // GA4 event
        ecommerce: {
          items: ga4Data,
        },
      })
      break
    case EVENT_COMMERCE_CART_REMOVE:
      dataLayer.push({
        event: 'removeFromCart', // UA event
        ecommerce: {
          remove: { products: data },
        }, 
      }, {
        event: 'remove_from_cart', // GA4 event
        ecommerce: {
          currency: 'AUD',
          items: ga4Data,
        },
      })
      break
    case EVENT_COMMERCE_CART_ADD:
      dataLayer.push({
        event: 'addToCart', // UA event
        ecommerce: {
          currencyCode: 'AUD',
          add: { products: data },
        },
      }, {
        event: 'add_to_cart', // GA4 event
        ecommerce: {
          currency: 'AUD',
          items: ga4Data,
        },
      })
      break
    case EVENT_COMMERCE_CHECKOUT_PROGRESS:
      if (data === undefined) {
        return
      }

      dataLayer.push({
        event: 'checkout', // UA event
        ecommerce: {
          checkout: {
            actionField: { step: data.step, option: data.option },
            products: data.products,
          },
        },
      })
      break
    case EVENT_COMMERCE_PURCHASE:
      dataLayer.push({
        ecommerce: { // UA event
          purchase: {
            actionField: {
              id: data.reference,
              affiliation: 'Public Website',
              revenue: data.subtotal,
              tax: data.tax,
              shipping: data.shipping,
            },
            products: data.products,
          },
        },
      }, {
        event: 'purchase', // GA4 event
        ecommerce: {
          transaction_id: data.reference,
          currency: 'AUD',
          value: data.subtotal, 
          tax: data.tax,
          shipping: data.shipping,
          items: ga4Data,
        },
      })
      break
    }
  },

  format: (event, data) => {
    switch (event) {
    case EVENT_COMMERCE_PURCHASE:
      return {
        reference: data.reference,
        subtotal: data.itemSubtotal,
        tax: data.totalTax,
        shipping: data.totalShippingCost,
        products: formatLineItemOrLineItems(data.items),
      }
    case EVENT_COMMERCE_PRODUCT_IMPRESSION:
    case EVENT_COMMERCE_PRODUCT_VIEW:
    case EVENT_COMMERCE_PRODUCT_CLICK:
      return formatProductOrProducts(data)
    case EVENT_COMMERCE_CART_ADD:
    case EVENT_COMMERCE_CART_REMOVE:
      return formatLineItemOrLineItems(data)
    case EVENT_COMMERCE_CHECKOUT_PROGRESS:
      if (!data.data.cart) { // Step 1 is hit before we load the products, so we need to wait for that
        return
      }

      return {
        step: data.step,
        option: data.accountType,
        products: formatProductOrProducts(data.data.cart.items),
      }
    }
  },
}

// initial mapping setup used by UA
const formatProductOrProducts = data => Array.isArray(data) ? formatProducts(data) : [formatProduct(data)]

const formatLineItemOrLineItems = data => Array.isArray(data) ? formatLineItems(data) : [formatLineItem(data)]

const formatProduct = product => ({
  id: product?.id,
  name: product?.fullTitle,
  price: product?.price || product?.defaultPrice || product?.variants?.[0]?.price,
  brand: 'Cormie', // Hard-coded, TODO: Implement brands?
  list: product?.gender?.[0]?.title,
  category: product?.productType?.[0]?.title,
  variant: product?.variants?.[0]?.title,
})

const formatProducts = products => products.map(formatProduct)

const formatLineItem = lineItem => ({
  ...formatProduct(lineItem?.purchasable?.product),
  quantity: lineItem?.qty,
})

const formatLineItems = lineItems => lineItems.map(formatLineItem)

// GA4 mapping setup
const mapGa4Data = data => Array.isArray(data) ? formatGa4Products(data) : [formatGa4Product(data)]

const formatGa4Product = product => ({
  item_id: product?.id,
  item_name: product?.name,
  price: product?.price || product?.defaultPrice || product?.variants?.[0]?.price,
  item_brand: 'Cormie', // Hard-coded, TODO: Implement brands?
  item_list_name: product?.list,
  item_category: product?.category,
  item_variant: product?.variant,
  quantity: product?.quantity,
})

const formatGa4Products = products => products.map(formatGa4Product)