import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { addressSchema } from '../../../components/checkout/checkout-step-2'
import { login } from '../../thunks/account/login'
import { register } from '../../thunks/account/register'
import { fetchCheckout } from '../../thunks/checkout/fetch-checkout'
import { guestCheckout } from '../../thunks/checkout/guest-checkout'

const addressAdapter = createEntityAdapter({
  selectId: model => model.uuid,
}, (a, b) => {
  if (a.id && b.id) {
    return a.id - b.id
  }

  return a.id ? -1 : 1
})

const newAddresses = ({ only = undefined, mode = 'button' } = {}) => ([
  {
    mode,
    uuid: uuid(),
    only: 'shipping',
  },
  {
    mode,
    uuid: uuid(),
    only: 'billing',
  },
].filter(typeof only === 'undefined' ? () => true : (a) => a.only === only))

const initialState = addressAdapter.getInitialState({
  newUser: false,
})

export const { reducer, actions, name } = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddresses: addressAdapter.setAll,
    addAddress: addressAdapter.upsertOne,
    updateAddress: addressAdapter.updateOne,
    changeMode: (state, action) => {
      addressAdapter.updateOne(state, {
        id: action.payload.uuid,
        changes: {
          mode: action.payload.mode,
        },
      })
    },
    onFormChange: (state, action) => {
      const { uuid, name, value } = action.payload
      const address = addressAdapter.getSelectors().selectById(state, uuid)
      const [_, key] = name.split('.')
      addressAdapter.updateOne(state, {
        id: uuid,
        changes: {
          [key]: value,
          isValid: addressSchema.isValidSync({ ...address }),
        },
      })
    },
    onFormInit: (state, action) => {
      const { uuid, values } = action.payload

      const changes = {}
      for (let key in values) {
        changes[key] = values[key]
      }

      addressAdapter.updateOne(state, {
        id: uuid,
        changes,
      })
    },
  },
  extraReducers: {
    [fetchCheckout.fulfilled]: (state, action) => {
      setupAddresses(state, action.payload.me?.customer?.addresses)
    },
    [login.fulfilled]: (state, action) => {
      setupAddresses(state, action.payload.customer?.addresses)
    },
    [register.fulfilled]: (state, action) => {
      setupAddresses(state, action.payload.customer?.addresses)
    },
    ['account/createAddress/pending']: (state, action) => {
      addressAdapter.upsertOne(state, {
        ...action.meta.arg,
        mode: 'card',
        only: undefined,
      })
    },
    ['account/createAddress/fulfilled']: (state, action) => {
      const oldAddresses = addressAdapter.getSelectors().selectAll(state).filter(a => a.only === undefined)

      addressAdapter.setAll(
        state,
        oldAddresses.map((address, i) => {
          if (address.only) {
            delete address.only
          }

          return {
            ...address,
            ...action.payload[i],
          }
        }).concat(newAddresses())
      )
    },
    ['account/createAddress/rejected']: (state) => {
      const addresses = addressAdapter.getSelectors().selectIds(state)

      addressAdapter.updateOne(state, {
        id: addresses[addresses.length - 1],
        changes: {
          mode: 'form',
        },
      })
    },
    [guestCheckout.fulfilled]: (state) => {
      addressAdapter.setAll(state, newAddresses({ mode: 'form' }))
    },
  },
})

const setupAddresses = (state, addresses) => {
  if (addresses && addresses.length > 0) {
    state.newUser = false
    addressAdapter.setAll(
      state, addresses.map(a => {
        const address = {
          mode: 'card',
          uuid: uuid(),
          ...a,
        }

        if (address.id && !address.countryId) {
          address.countryId = 13
        }

        return address
      }).concat(newAddresses())
    )
  } else {
    state.newUser = true
    addressAdapter.setAll(state, newAddresses({ mode: 'form' }))
  }
}

export const selectors = addressAdapter.getSelectors(state => state[name])

export const selectAddressesByType = (type) => (state) => selectors.selectAll(state).filter(
  address => typeof address.only === 'undefined' || address.only === type
)

export const selectAddressIdsByType = (type) => (state) => selectors.selectAll(state).filter(
  address => typeof address.only === 'undefined' || address.only === type
).map(addressAdapter.selectId)

export const { setAddresses, addAddress, updateAddress, changeMode, onFormChange, onFormInit } = actions
