import { createAsyncThunk } from '@reduxjs/toolkit'
import { gql } from 'apollo-boost'
import { client } from '../../../apollo/client'

const GET_PRODUCTS = gql`
  query FundingEligibility($activity: ProductSelectorActivity!, $gender: ProductSelectorGender!, $type: ProductSelectorType!, $level_day: ProductSelectorIncontinenceLevel, $level_night: ProductSelectorIncontinenceLevel, $size: ProductSelectorSize!, $whom: ProductSelectorWhom!) {
    productSelector(activity: $activity, gender: $gender, level_day: $level_day, level_night: $level_night, size: $size, type: $type, whom: $whom) {
      ... on incontinenceProducts_Product {
        id
        uri
        slug
        productsummary
        fullTitle
        customProductTitle
        volume
        packSize
        defaultPrice
        showNewLabel
        goodFor {
          ... on goodFor_BlockType {
            id
            use
          }
        }
        variants {
          id
          title
        }
        listingGallery {
          alt: title
          ... on local_Asset {
            url
          }
          ... on s3_Asset {
            url
          }
        }
      }
      ... on inserts_Product {
        id
        uri
        slug
        productsummary
        fullTitle
        customProductTitle
        volume
        packSize
        defaultPrice
        showNewLabel
        goodFor {
          ... on goodFor_BlockType {
            id
            use
          }
        }
        variants {
          id
          title
        }
        listingGallery {
          alt: title
          ... on local_Asset {
            url
          }
          ... on s3_Asset {
            url
          }
        }
      }
    }
  }
`

export const getSuggestions = createAsyncThunk('productSelector/getSuggestions', async (form) => {
  // Check if the user has specified "none" to both of the incontinence levels
  if (['BLADDER', 'BOTH'].indexOf(form.type) !== -1 && form.level_day === null && form.level_night === null) {
    throw new Error('You have indicated none on your day and night levels of incontinence, please try again')
  }

  const { data } = await client.query({
    query: GET_PRODUCTS,
    variables: form,
    fetchPolicy: 'no-cache',
  })

  return data.productSelector
})
