import { getKlaviyo } from '../../store/reducers/klaviyo'
import { EVENT_COMMERCE_CHECKOUT_START, EVENT_COMMERCE_PRODUCT_VIEW } from '../events'

export const klaviyoHandler = {
  handle: (event, data) => {
    const klaviyo = getKlaviyo()

    switch (event) {
    case EVENT_COMMERCE_PRODUCT_VIEW:
      klaviyo.push(['track', 'Viewed Product', data])
      klaviyo.push(['trackViewedItem', data])
      break
    case EVENT_COMMERCE_CHECKOUT_START:
      klaviyo.push(['track', 'Started Checkout', data])
      break
    }
  },

  format: (event, data) => {
    switch (event) {
    case EVENT_COMMERCE_PRODUCT_VIEW:
      return {
        'Title': data.fullTitle,
        'ItemId': data.id,
        'variantId': data.variants?.[0]?.id,
        'Categories': [].concat(data.productType.map(t => t.title)).concat(data.gender.map(g => g.title)),
        'ImageUrl': data.gallery?.[0]?.url,
        'Url': window.location.href,
        'Metadata': {
          'Price': parseFloat(data.variants?.[0]?.price),
          'SKU': data.variants?.[0]?.sku,
        },
      }
    case EVENT_COMMERCE_CHECKOUT_START:
      return {
        '$event_id': `${data.number}_${Math.floor(Date.now() / 1000)}`,
        '$value': data.itemSubtotal,
        'ItemNames': data.items.map(item => item.purchasable.product.fullTitle),
        'CheckoutURL': window.location.href,
        'Items': data.items.map(item => ({
          'ProductID': item.purchasable.product.id,
          'SKU': item.purchasable.sku,
          'ProductName': item.purchasable.product.fullTitle,
          'Quantity': item.qty,
          'ItemPrice': item.subtotal,
          'RowTotal': item.total,
          'ProductURL': new URL(`${window.location.origin}/${item.purchasable.product.uri}`).href,
          'ImageURL': item.purchasable.product.gallery?.[0]?.url,
        })),
      }
    }
  },
}
