import React, { Component } from 'react'
import { Link as BaseLink, navigate as baseNavigate } from 'gatsby'
import { connect } from 'react-redux'
import { isSsr } from '../utils/is-ssr'

class Link extends Component {
  static defaultProps = {
    href: '#',
    onClick: null,
  }

  get externalProps() {
    if (typeof this.props.href?.match !== 'function') {
      return {}
    }

    return this.props.href.match(/^#/)
      ? {}
      : {
        rel: 'noopener',
        target: '_blank',
      }
  }

  render() {
    const { href, children, onClick, location, dispatch: _, ...props } = this.props

    if (typeof onClick === 'function') {
      props.onClick = onClick
    }

    const to = sanitiseUrl(href)

    if (location === to) {
      const onClick = props.onClick

      props.onClick = (e) => {
        if (typeof onClick === 'function') {
          onClick(e)
        }

        window.location.reload()
      }
    }

    return isInternal(href) && !props.onClick ? (
      <BaseLink to={to} {...props}>
        {children}
      </BaseLink>
    ) : (
      <a href={to} {...{ ...this.externalProps, ...props }}>
        {children}
      </a>
    )
  }
}

export default connect(
  state => ({
    location: state.location.pathname,
  }),
)(Link)

export const navigate = (url) => {
  return baseNavigate(sanitiseUrl(url))
}

export const sanitiseUrl = url => {
  if (typeof url !== 'string') {
    return url
  }

  if (isAbsoluteUrl(url) || hasProtocol(url)) {
    if (isInternal(url)) {
      const { href, origin } = new URL(url)

      return href.replace(origin, '')
    }

    return url
  }

  if (isHashLink(url)) {
    return url
  }

  return url.charAt(0) === '/' ? url : `/${url}/`
}

const isAbsoluteUrl = url => /^https?/.test(url)

const hasProtocol = url => /^\w+:/.test(url)

const isHashLink = url => /^#/.test(url)

const isInternal = url => {
  if (typeof url !== 'string') {
    return false
  }

  if (isHashLink(url)) {
    return true
  }

  if (isRelative(url)) {
    return true
  }

  if (isAbsoluteUrl(url) && !isSsr()) {
    const host = new URL(url).host

    return host === window.location.host
  }

  return !hasProtocol(url)
}

const isRelative = url => /^\//.test(url)
